var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "searchWrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                "label-width": "150px",
                model: _vm.formInline,
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #c0ccda",
                    "border-radius": "4px",
                    padding: "15px 15px 50px",
                  },
                },
                [
                  _c("h2", { staticClass: "title" }, [
                    _c("div", { staticClass: "title_icon" }),
                    !_vm.isEdit
                      ? _c("span", [_vm._v("添加活动基本信息")])
                      : _vm._e(),
                    _vm.isEdit
                      ? _c("span", [_vm._v("修改活动基本信息")])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { width: "800px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动名称:", required: "" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "50",
                              minlength: "2",
                              placeholder: "输入活动名称",
                            },
                            model: {
                              value: _vm.formInline.couponActivityName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "couponActivityName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.couponActivityName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动时间:", required: "" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              placeholder: "选择日期",
                              "picker-options": _vm.pickerOptions1,
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            model: {
                              value: _vm.formInline.start,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "start",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.start",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "coupon_box",
                          attrs: { label: "关联优惠券:", required: "" },
                        },
                        [
                          _c("ticket-item", {
                            ref: "ticketFrom",
                            model: {
                              value: _vm.formInline,
                              callback: function ($$v) {
                                _vm.formInline = $$v
                              },
                              expression: "formInline",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "领取规则:", required: "" } },
                        [
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#606266",
                                  "margin-bottom": "10px",
                                },
                              },
                              [
                                _vm._v(" 活动期间每人每日可领取 "),
                                _c("el-input", {
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "150px",
                                  },
                                  model: {
                                    value: _vm.formInline.onePersonOneDayTimes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "onePersonOneDayTimes",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formInline.onePersonOneDayTimes",
                                  },
                                }),
                                _vm._v(" 次 "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { color: "#606266" } },
                              [
                                _vm._v(" 活动期间每人总共可领取 "),
                                _c("el-input", {
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "150px",
                                  },
                                  model: {
                                    value: _vm.formInline.onePersonTotalTimes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "onePersonTotalTimes",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formInline.onePersonTotalTimes",
                                  },
                                }),
                                _vm._v(" 次 "),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", margin: "20px 0" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.submitData },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }